import React from 'react';
import './Card.css';

const Card = props => {
    const {
			artist,
			imageURL,
			reason,
			style,
			submissionBy,
			trackName,
		} = props;    
    const defaultImageURL = 'https://storage.googleapis.com/bpnyc-media-production/Gore/default_album_art.png';
    
    return (    
      <div className="cardContainer" style={{zIndex: '-1', ...style}}>

        <div className="imgContainer">
          <img alt="" src={imageURL || defaultImageURL}/>
          <div className="song-info-container">
            <div className="song"> {trackName} </div>
            <div className="artist">by {artist}</div>
          </div> 
        </div>

        <div className="textContainer">
          <div className="descriptionContainer">
            { reason ? 
              <p className="reason">"{reason}"</p> : ''
            }
          </div>
          <div className="submittedByLabel">submitted by </div>
          <div className="submissionBy">{submissionBy}</div>
        </div>

      </div>
    )
};

export default Card;