import { GET_USER, USER_FAILURE, ADD_USER } from "./types";
import { addResponses } from "./appActions";
import * as userApi from "../api/user.api";

export const authenticateUser = (token, id) => async (dispatch) => {
  // ERROR HANDLE NO TOKEN IN URL
  if (!token) return dispatch({ type: USER_FAILURE });

  try {
    const data = await userApi.authenticateUser(id, token)
    return dispatch({
      type: GET_USER,
      user: data.user,
      message: data.error,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_FAILURE });
  }
};

export const addUser = (responses) => async (dispatch) => {
  const { response, newUser } = responses;
  let eventId = response.eventId;
  try {
    const data = await userApi.createUser(eventId, newUser)
    //REFACTOR SECTION TO USE OBJECT INSTEAD OF ARRAYS AS DATA TYPE OBJECT WILL REDUCE THE COMPLEXITY ADDING A NEW USER/ RESPONSES

    //ASSIGN NEWLY CREATED USER INFO WITH CORRECT DATA MODEL
    let copyResponse = { ...response, userId: data.user.id };
    let copyResponses = { ...responses };
    copyResponses["response"] = { ...copyResponse };
    copyResponses["newUser"] = { ...data.user };

    return (
      dispatch({
        type: ADD_USER,
        user: data.user,
      }),
      dispatch(addResponses(copyResponses))
    );
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_FAILURE });
  }
};
