import React from "react";
import { connect } from "react-redux";
import { addPage, removePage } from "../../../redux/actions/appActions";
import SubmitPage from "./QuestionnaireFinalFormPage";
import QuestionnaireForm from "./QuestionnaireBasicForm";

const QuestionnaireManager = (props) => {
  const { prev, next, page, last, onSubmit, textColor, question } = props;

  return (
    <div id="questionnaireForm">
      {page === last ? (
        <SubmitPage
          question={question}
          textColor={textColor}
          previousPage={prev}
          onSubmit={onSubmit}
        />
      ) : (
        <QuestionnaireForm
          question={question}
          textColor={textColor}
          previousPage={prev}
          showNext={page > 0 ? true : false}
          onSubmit={next}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    page: state.app.page,
    last: state.app.last,
    defaultPage: state.app.defaultPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    next: () => dispatch(addPage()),
    prev: () => dispatch(removePage()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireManager);
