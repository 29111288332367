export const fetchEvents = async () => {
    const response = await fetch("/admin/events");
    return await response.json();
}

export const fetchEvent = async (id) => {
    const response = await fetch(`/admin/events/${id}`);
    return await response.json();
}

export const createEvent = async (event) => {
    const response = await fetch("/admin/events", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(event),
      });
      return await response.json();
}

export const updateEvent = async (event) => {
    const response = await fetch(`/admin/events/${event.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(event),
      });
      return await response.json();
}

export const deleteEvent = async (id) => {
    const response = await fetch(`/admin/events/${id}`, {
        method: "DELETE",
      });
      return await response.json();
}