import _ from "lodash";

// CREATE OBJECT OF TABLE DATA
export const structureUserResponseObject = (
  responses = [],
  questionnaire = []
) => {
  if (responses.length === 0) return responses;

  // TABLE HEADERS USING RESPONSE DATA
  let keys = Object.keys(responses[0])
    .filter(
      (key) =>
        ![
          "responses",
          "questionnaire",
          "eventId",
          "userId",
          "spotifies",
        ].includes(key)
    )
    .concat(["name", "email"]);

  //FILTER FOR AN UNDEFINED OR NULL RESPONSES
  let filteredResponses = responses.filter(
    (response) => response.responses !== null
  );
  return filteredResponses.map((response) => {
    let hashResponses = createResponseObjects(
      response.responses,
      response.questionnaire
    );

    let responseHeader = createHeaders(keys, response);

    Object.assign(responseHeader, hashResponses);
    return responseHeader;
  });
};

// CREATE OBJECT OF USER INFO THAT IS NOT RESPONSES
const createHeaders = (keys, user) => {
  let object = {};

  Object.keys(user).map((key) => {
    const value = _.isObject(user[key])
      ? _.find(user[key], "url")
      : user[key].toString();

    if (keys.includes(key) && value) {
      return (object[key] = value);
    }
  });

  return object;
};

// CREATE OBJECT OF USER QUESTIONS AND RESPONSES
const createResponseObjects = (responses, questions) => {
  let object = {};
  questions.map((key, idx) => {
    const value = responses[idx];
    return (object[key] = value);
  });
  return object;
};

// CREATE LINK FOR SPOTIFY SONGS
export const createSpotifyLink = (tabledata, spotifydata) => {
  if (spotifydata) return tabledata === spotifydata.song ? spotifydata.url : "";
};

//TABLE DATA MANAGEMENT FOR USER RESPONSES
export const structureTableData = (data, questionnaire) => {
  if (data) {
    console.log("data", data);
    const showTable = data && data.length > 0;
    const parseTableData = data ? structureUserResponseObject(data) : [];

    // TABLE HEADERS USING QUESTIONNAIRES ADDING PETINIENT RESPONSE DATA
    const questionnaireHeaders =
      questionnaire && questionnaire.length > 0
        ? ["reviewed", "name", "email"].concat(
            questionnaire.slice(2).map((question) => question.question)
          )
        : [];

    const tableRows = data ? parseTableData : [];
    const spotifyData = data.map((response) => {
      if (response.spotifies[0])
        return {
          song: response.spotifies[0].song,
          url: response.spotifies[0].url,
        };
      else return null;
    });

    const results = {
      showTable,
      tableRows,
      spotifyData,
      parseTableData,
      questionnaireHeaders,
    };

    console.log("tableRows", tableRows);
    console.log("spotify", spotifyData);
    return results;
  } else {
    return [];
  }
};

//GATHER CSV DATA -- ##### REFACTOR INTO CSV HEADERS AND ROWS AND SEPARATE SPOTIFY, AND MOVE TO A DIFFERENT FILE
export const csvData = (responses, questionnaire) => {
  let keys;
  let allKeys;
  let spotifies;
  let spotifyRows;

  if (questionnaire.length === 0 || responses.length === 0) return;
  else {
    keys = ["reviewed", "name", "email"].concat(
      questionnaire.slice(2).map((question) => question.question)
    );
    spotifies = responses[0].spotifies;

    allKeys = createSpotifyKeys(spotifies, keys);
    spotifyRows = createSpotifyObjects(responses);
  }

  //CREATE CSV HEADERS
  const tableHeaders = allKeys.map((name) => {
    let object = {};
    object["label"] = name.charAt(0).toUpperCase() + name.slice(1);
    object["key"] = name;
    return object;
  });

  // CALL CSV ROWS
  const { tableRows } = structureTableData(responses);
  const allData = tableRows.map((data, index) =>
    Object.assign({}, data, spotifyRows[index])
  );
  return { allData, tableHeaders };
};

//CREATE REVIEWED SPOTIFY OBJECT
export const createUpdatedResponseObject = (data) => {
  return { responseId: data.id, reviewed: data.reviewed.toString() };
};

// CREATE OBJECT FROM SPOTIFY
const createSpotifyObjects = (responses) => {
  return responses.map((response) => response.spotifies[0]);
};

//CREATE SPOTIFY TABLE HEADERS
const createSpotifyKeys = (spotifies, keys) => {
  let spotify =
    spotifies.length !== 0 ? spotifies.find((data) => data.song) : [];
  let spotifyKeys = Object.keys(spotify).filter(
    (key) => !["id", "reviewed", "person", "reason"].includes(key)
  );
  return keys ? keys.concat(spotifyKeys) : spotifies.find((data) => data.song);
};

//CREATE CAPITALIZE FUNCTION FOR TABLE RESPONSES
export const capitalize = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);
