import React from 'react';
import Swipeable from 'react-swipy';
import backgroundImage from '../../../../assets/HomePageBackground.png';
import { connect } from 'react-redux';
import { removePlaylistCard } from '../../../../redux/actions/appActions';
import Card from '../Card/Cards';

import './Playlist.css';

const getSpotifyPlaylistLink  = eventID  => {
  switch(eventID){
    case '10':
      return 'https://open.spotify.com/playlist/2LBbxIYtzsJyuJAGcCdMas?si=1da1821d9bab4d6a'
    case '15':
      return 'https://open.spotify.com/playlist/0Fyq7dBseWDer2d1VFO9Ak'
    case '18':
      return 'https://open.spotify.com/playlist/2kkajf1fEyFm83I3wukfVB'
    case '19':
      return 'https://open.spotify.com/playlist/079BGOPt57Vw0Qv5DZruFr'
    case '20':
      return 'https://open.spotify.com/playlist/2dWonhlXlcvgLVhIToBT7W?si=916ad1a26b094bda'
    case '21':
      return 'https://open.spotify.com/playlist/2dWonhlXlcvgLVhIToBT7W'
    case '22':
      return 'https://open.spotify.com/playlist/6NVE6uO4yG2LSOJrZPzjIo'
    default: 
      return 'https://open.spotify.com/playlist/0DiPCQG83PTCiSqamOzfjO?si=Hh2HlXhVRzyj4pqOzYbrLA'
  }
}

class Playlist extends React.Component {
  state = {
		timer: setTimeout(() => {}, 3000),
		direction: '',
	};

  // Handles card removal
  remove = () => {
    const { playlist } = this.props;
    this.props.removeCard(playlist);
  };

  render = () => {
    const { playlistUrl, playlist, id } = this.props;
    const background = playlistUrl || backgroundImage;
    const displayDeck =
      playlist.length === 1
        ? { visibility: 'hidden' }
        : { visibility: 'visible' };
    const htmlCards = [];

    playlist.map((song) => {
      return htmlCards.push(
        <Card
          key={song['reason']}
          trackName={song['song']}
          artist={song['artist']}
          reason={song['reason']}
          submissionBy={song['person']}
          imageURL={song['imageUrl']}
          style={{
            transform: 'rotate(0deg)',
            transition: 'transform .15s ease-in-out',
          }}
        />
      );
    });
    let timer;

    return (
      <div style={displayDeck} className="playlistContainer">
        <img className="background" alt="" src={background} />
        {playlist.length > 2 ? (
					<div className="wrapperContainer">
						<Swipeable
							className="swipy-box"
							buttons={({ left, right }) => {
								const directions = [left, right];
								if (window.innerWidth >= 900) {
									timer
										? clearTimeout(timer)
										: (timer = setTimeout(
												directions[Math.round(Math.random())],
												5000
											));
								}
								return (
									<div className="uiButtonContainer">
										<div>
											<a className="uiButton nextButton" onClick={right}>
												Next 
											</a>
										</div>
										<div className="uiButtonsSpotifySubmit">
											<a
												className="uiButton"
												target={'_blank'}
												href={`/attendee/events/${this.props.id}/questionnaire`}
											>
												Submit a Song
											</a>
											<a
												className="uiButton"
												target={'_blank'}
												href={getSpotifyPlaylistLink(id)}
											>
												Listen with Spotify
											</a>
										</div>
									</div>
								);
							}}
							onAfterSwipe={() => {
								this.remove();
							}}
							min={225}
						>
							{htmlCards[0]}
						</Swipeable>
						<Card
							key={playlist[2]['reason']}
							trackName={playlist[2]['song']}
							artist={playlist[2]['artist']}
							reason={playlist[2]['reason']}
							submissionBy={playlist[2]['person']}
							imageURL={playlist[2]['imageUrl']}
							style={{
								transform: 'rotate(-3.5deg) scale(.95)',
								transition: 'transform .1s ease-in-out',
							}}
						/>
						<Card
							key={playlist[1]['reason']}
							trackName={playlist[1]['song']}
							artist={playlist[1]['artist']}
							reason={playlist[1]['reason']}
							submissionBy={playlist[1]['person']}
							imageURL={playlist[1]['imageUrl']}
							style={{
								transform: 'rotate(4deg) scale(.98)',
								transition: 'transform .1s ease-in-out',
							}}
						/>
					</div>
        ) : (
          <h1>
            At least four songs are necessary to create a playlist.
          </h1>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeCard: (playlist) => dispatch(removePlaylistCard(playlist)),
  };
};

export default connect(null, mapDispatchToProps)(Playlist);
