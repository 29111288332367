import React from 'react';
import bcrypt from 'bcryptjs';
import { LoginPage } from '@blueprintnyc/bpnyc-common'
import { Alert as AlertContainer } from '@blueprintnyc/bpnyc-common';
import { HASH } from 'config'
const { Alerter } = AlertContainer;
const Alert = new Alerter('Login');

const loginContainer = props => {
  const showSuccessTitle = (successMessage) => Alert.success('Success', successMessage);
	const showErrorTitle = (errorMessage) => Alert.error('Error', errorMessage);

  const successCb = () => {
    const { history } = props
    showSuccessTitle("Login Successful")
    history.push('events/')
  }

  const errorCb = () => {
    showErrorTitle("Wrong Password")
  }

  const handleSubmit = (data) => {
    const { password } = data
    localStorage.setItem("sp-access", password)
    const matched = bcrypt.compareSync(password, HASH);
    matched ? successCb() : errorCb()
  }

  return <LoginPage onSubmit={handleSubmit} passwordOnly={true} title="SPOTIFY LOGIN" />
};

export default loginContainer

