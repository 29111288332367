import {SHOW_MODAL, HIDE_MODAL} from "../actions/types";

const initialState = {
  modalType: {
    name: null,
    title: null,
    buttonLabel: null
  },
  modalProps: {
    open: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modal: action.open,
        name: action.name,
        title: action.title,
        buttonLabel: action.buttonLabel,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
