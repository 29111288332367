import React from "react";
import Select from "react-select";
 import "./render.css";


 // NEED TO FIX, REDUX FORM IS NOT RECOGINIZING THIS AS A FIELD THUS ANSWERS ARE NOT RECORDED;
export const renderMultiselect  = ({ input, options, className }) => {
  const { name, value, onBlur, onChange, onFocus } = input;

  return (
    <Select
      name={name}
      defaultValue={"Select ......"}
      isMulti
      options={options}
      value={input.value || []}
      onChange={(value) => onChange(value)}
      onBlur={() => onBlur(value)}
      onFocus={onFocus}
      className={className}
    />
  );
};

export default renderMultiselect;



     