import React from "react";
import { IconButton, IconModalButton, Form } from '@blueprintnyc/bpnyc-common';
import { Link } from "react-router-dom";
import {RoundedBoxH} from '@blueprintnyc/bpnyc-common';
import { connect } from 'react-redux'
import { updateEvent, deleteEvent } from 'redux/actions/eventActions'


function DashboardLink(props) {
	const { event } = props;
	return <Link to={`/admin/events/${event.id}`}>
    <IconButton color="#0d62a3" text="Settings" />
  </Link>
}

function AttendeeLink(props) {
	const { event } = props;
	return <IconButton link={`/attendee/${event.id}`} icon="link" color="#0d62a3" />;
}

const updateModalContent = (props) => {
  const { event, updateEvent, updateState } = props

  const handleSubmit = (data) => {
      updateEvent(data)
      updateState(["showModal", "content"],[false, null])
  };
  
  return (
    <>
      <h3>{`Update ${event.name} Event`}</h3>
      <Form onFinish={handleSubmit}>
          <Form.Item label="Name" name="name">
              <Form.Input />
          </Form.Item>
          <IconButton 
              type="primary" 
              htmlType="submit" 
              color="#0D62A5"
          > Update </IconButton>
      </Form>
    </>
  ) 
}

const DeleteEvent = (props) => {
  const { deleteEvent, event } = props
  const deleteButton = <IconButton color="#dc3545" icon="trash" />;
	const modalContent = <h3>Are you sure?</h3>;
	const closeModalButton = <IconButton color="#dc3545">Delete</IconButton>;
  const handleDelete = () => {
		deleteEvent(event.id)
	};

  return(
    <IconModalButton
      onClickAction={handleDelete}
      button={deleteButton}
      modalContent={modalContent}
      closeModalButton={closeModalButton}
    />
  )
}

const EventCard = (props) => {
  const { event, updateState }  = props;

  return (
    <RoundedBoxH title={event.name}>
      <DeleteEvent {...props} />
      <DashboardLink event={event} />
      <IconButton 
        color="#808080" 
        icon="edit" 
        styleOW={{background: "#808080"}} 
        onClick={()=>{updateState(["showModal", "content"],[true, updateModalContent(props)])}}
      />
      <AttendeeLink event={event} />
    </RoundedBoxH>
  );
};

const msp = () => {}

const mdp = (dispatch) => ({
    updateEvent: (data) => dispatch(updateEvent(data)),
    deleteEvent: (id) => dispatch(deleteEvent(id)),
})

export default connect(msp, mdp)(EventCard);
