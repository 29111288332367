export const authenticateUser = async (id, token) => {
    const response = await fetch(`/api/authorizeUser/${id}/${token}`);
    return await response.json();
}

export const createUser = async (eventId, newUser) =>{
    const resp = await fetch(`/admin/events/${eventId}/users`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser),
      });
      return await resp.json();
}