import React from 'react'
import bcrypt from 'bcryptjs';
import { HASH } from 'config'
import Navbar from '../Navbar'
import Unauthorized from '../Unauthorized'

const RestrictedRoute = (props) => {
    const { children } = props
    const accessPhrase = localStorage.getItem('sp-access')
    const authorized = accessPhrase ? bcrypt.compareSync(accessPhrase, HASH) : false
    const navbar =  (navProps)=>(<Navbar {...props} {...navProps} authorized={authorized}/>)
    const propsToSend = {...props, Navbar: navbar}
    delete propsToSend.children


    return authorized ? React.cloneElement(children, { ...propsToSend }) : <Unauthorized Navbar={navbar}/>
}

export default RestrictedRoute