import React, { Component } from 'react';
import { connect } from "react-redux";        
import { getPlaylist } from "../../../redux/actions/appActions";
import Playlist from './Playlist/Playlist';


class PlaylistContainer extends Component {
  componentDidMount() {
    const { match, getPlaylist } = this.props
    const { id } = match.params
    getPlaylist(id || 9); // adding 9 for card eca 1940
  }

  render() {
    const { playlistUrl, playlist, match } = this.props;
    const { id } = match.params 

    return (
      <div>
        <Playlist playlist={playlist} playlistUrl={playlistUrl} id={id || 9} /> 
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    playlist: state.app.playlist,
    playlistUrl: state.app.playlistUrl
  }
}

const dispatchStateToProps = dispatch => {
  return {
    getPlaylist: (id) => dispatch(getPlaylist(id))
  }
}

export default connect(mapStateToProps, dispatchStateToProps)(PlaylistContainer);

