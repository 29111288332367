import React, { useState } from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { addEvent, updateEvent } from "../../../redux/actions/eventActions";
import { Label, Input, Button } from "reactstrap";
import { colorPicker } from "../../../helpers/formData";
import { SketchPicker } from "react-color";
import "./EventForm.css"

let EventForm = (props) => {
  const { handleSubmit, reset, submitting, buttonLabel, toggle } = props;
  

  const [background, setBackground] = useState(props.initialValues.background);
  const [textColor, setText] = useState(props.initialValues.textColor);
  
  const submit = (values) => {
    let current = Object.assign({}, values, { background, textColor });
      if (buttonLabel === "Create") {
        return props.addEvent(current);
      } else {
        return props.updateEvent(current);
      }
  };

  const currentValue = (value, previousValue) =>
    value === previousValue ? previousValue : value;
  
    const showSubmit =
    props.initialValues.textColor === textColor &&
    props.initialValues.background === background

  return (
    <form style={styles.container} onSubmit={handleSubmit(submit)}>
      <div>
        <div>
          <Field
            name="name"
            component="input"
            type="text"
            placeholder="Event Name"
            style={styles.input}
          />
        </div>
      </div>
      <div style={styles.colorContainer}>
        {colorPicker.map((color, idx) => {
          let colorFor = color.name === "background" ? background : textColor;
          let variable =
            color.name === "background" ? "background" : "textColor";
          return (
            <div key={idx} style={styles.subContainer}>
              <div style={styles.colorInfo}>
                <Label style={styles.colorLabel}>{color.label}</Label>
                <Input
                  style={styles.colorInput}
                  name={color.name}
                  component="input"
                  value={currentValue(colorFor, props.initialValues[variable])}
                  onChange={() => colorFor}
                />
              </div>
              <div style={styles.pickerContainer}>
                <SketchPicker
                  className={"sketch-picker"}
                  color={colorFor}
                  onChange={(e) =>
                    color.name === "background"
                      ? setBackground(e.hex)
                      : setText(e.hex)
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Field
          name="playlistUrl"
          component="input"
          type="text"
          placeholder="Enter Playist Background Image Url........"
          style={styles.input}
        />
      </div>
      <div>
        <Button
          style={styles.button}
          color="success"
          type="submit"
          disabled={submitting}
          onClick={toggle}
        >
          {" "}
          Submit{" "}
        </Button>
        <Button
          style={styles.button}
          color="secondary"
          type="button"
          disabled={showSubmit || submitting}
          onClick={reset}
        >
          {" "}
          Clear Values{" "}
        </Button>
      </div>
    </form>
  );
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  subContainer: {
    width: "100%"
  },
  input: {
    padding: "5px",
    marginBottom: "5px",
    margin: "10px",
    width: "25em",
  },
  colorContainer: {
    display: "flex",
    justifyContent: "center",
  },
  colorInfo: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    padding: "10px",
    width: "100%"
  },
  colorLabel: {
    textAlign: "center",
    padding: "3px",
  },
  colorInput: {
    width: "6em",
    margin: "auto",
    textAlign: "center",
    background: "#D2D3DB"
  },
  button: {
    margin: "15px"
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    addEvent: (event) => dispatch(addEvent(event)),
    updateEvent: (event) => dispatch(updateEvent(event)),
    selectColor: (color) => change("eventForm", "field", color),
  };
};

EventForm = connect((state, ownProps) => {
  return { initialValues: ownProps.initialValues || state.events.event };
}, mapDispatchToProps)(EventForm);

export default reduxForm({
  form: "eventForm", // a unique identifier for this form
  enableReinitialize: true,
})(EventForm);