import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateResponses } from "../../../redux/actions/appActions";

const Checkboxes = (props)  => {
  const { response, reviewed, eventId } = props;

  const [checked, setChecked] = React.useState(reviewed === 'true');

  const handleChange = (event) => {
    let id = parseInt(response.id)
  
    const copyResponse = { ...response, reviewed: event.target.checked, id, eventId: eventId  };

    setChecked(event.target.checked);

    props.updateResponse(copyResponse)
  };

  return (
    <div>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        value={response.id}
      />
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    eventId: state.events.event.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateResponse: response => dispatch(updateResponses(response)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkboxes));