import {
  ADD_USER,
  USER_FAILURE,
  GET_USER
} from "../actions/types"

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  eventId: localStorage.getItem('eventId'),
  message: "",
  user: {
      id: 0,
      name: "DEFAULT USER",
      email: "DEFAULT@EXAMPLE.COM",
      completed: false
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      const user = action.user;
      return {
        ...state,
        isLoading: true,
        user: user ? user : state.user,
        message: action.message
      };
    case USER_FAILURE:
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        user: initialState.user,
        isAuthenticated: false,
        isLoading: false,
      };
    case ADD_USER:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
}
