import React from "react";
import Select from "react-select";
import "./render.css";

export const renderSelectList = ({ input, options, className }) => {
  const { name, value, onBlur, onChange, onFocus } = input;

  return (
    <Select
      name={name}
      defaultValue={"Select ......"}
      options={options}
      value={input.value}
      onChange={(value) => onChange(value)}
      onBlur={() => onBlur(value)}
      onFocus={onFocus}
      className={className}
    />
  );
};

export default renderSelectList;
