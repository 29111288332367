import React from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import CreateQuestion from '../Modals';
import { BsFillMusicPlayerFill } from "react-icons/bs";
import ExportData from "../CSV";

// DASHBOARD GUI FOR EVENTS/EVENT/RESPONSES COMPONENTS

const Dashboard = (props) => {
  const { component, eventId, eventName, questionnaire, responses, match } = props;
  const { id } = match.params

  return (
    <div>
      <div>
        <h1 style={{ padding: "0.2em" }}>{eventName}</h1>
        <hr />
      </div>
      <div style={styles.dashboard}>
        {component === "Questions" ? (
          <div style={styles.container}>
            <div style={styles.subContainer}>
              <h3>{"Questionnaire Link: "}</h3>
              <Link replace to={`/attendee/events/${id}/questionnaire`} style={styles.link}>
                {`${eventName} Url`}
              </Link>
            </div>
            <CreateQuestion
              name={"question"}
              buttonLabel={"Create"}
              title={"Create a Question"}
              eventId={eventId}
            />
          </div>
        ) : (
          <div style={styles.container}>
            <div style={styles.subContainer}>
              <h3>{"Playlist Link: "}</h3>
              <Link replace to={`/attendee/events/${id}/playlist`} style={styles.link}>
                {(eventName + " Playlist")}
              </Link>
            </div>
            {!responses ? null : 
            <div style={styles.buttonContainer}>
             <Button style={styles.button} disabled>
                {"Spotify "} <BsFillMusicPlayerFill />
              </Button>    
              <Button color="secondary" style={styles.button}>
                <ExportData
                  questionnaire={questionnaire}
                  responses={responses}
                  eventName={eventName}
                />
              </Button>
            </div>
            }
          </div>
        )}
      </div>
    </div>
  );

}

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "80%"
  },
  subContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "1em",
    width: "75%",
  },
  dashboard: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center'
  },
  link: {
    fontSize: "1em",
    fontWeight: "bold",
    textDecoration: "underline",
    padding: "0.3em",
    marginLeft: "0.5em",
    color: 'grey'
  },
  buttonContainer: {
    display: "flex",
    width: "80%",
    justifyContent: 'center'
  },
  questionButtonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
  },
  button: {
    padding: "5px",
    margin: "5px",
    width: "8vw",
    color: "white"
  }
};


export default withRouter(Dashboard);
