import React from "react";
import AdminRoutes from './admin/AdminRoutes'
import AttendeeRoutes from './attendee/AttendeeRoutes'
import Playlist from './attendee/playlist'
import Home from './admin/Home'
import { Switch, Route } from "react-router-dom";

const isOneRare = window.location.origin.includes("onerareplaylist.com")


const Routes = () => {
    return (
        <div className="spotify-app">
            <Switch>
                    <Route path="/admin/" component={AdminRoutes}/>
                    <Route path="/attendee/" component={AttendeeRoutes}/>
                    <Route exact path="/" component={ isOneRare ? Playlist : Home }/>
            </Switch>
        </div>
    )
}

export default Routes