import {
  GET_EVENT,
  GET_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  EVENT_FAILURE,
  EVENT_LOADING,
  SET_DATA_CHANGED,
} from "./types";
import * as eventApi from '../api/event.api'

// FETCH DATA
export const getEvents = () => async (dispatch) => {
  // EVENT LOADING
  dispatch({ type: EVENT_LOADING });
  try {
    const data = await eventApi.fetchEvents()
    dispatch({
      type: GET_EVENTS,
      events: data.events,
      message: data.message,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: EVENT_FAILURE });
  }
};

export const getEvent = (id) => async (dispatch) => {
  // ERROR HANDLE IF ID IS UNDEFINED
  if (!id) return dispatch({ type: EVENT_FAILURE });

  // EVENT LOADING
  dispatch({ type: EVENT_LOADING });
  try {
    const data = await eventApi.fetchEvent(id)
    dispatch({
      type: GET_EVENT,
      event: data.event,
      eventId: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: EVENT_FAILURE });
  }
};

// EVENT CRUD ACTIONS
export const addEvent = (event) => async (dispatch) => {
  try {
    const data = await eventApi.createEvent(event)
    return (
      dispatch({
        type: ADD_EVENT,
        event: data.event,
        dataChanged: data.dataChanged,
      }),
      dispatch(setDataChange()),
      dispatch(getEvents())
    );
  } catch (error) {
    console.log(error);
    dispatch({ type: EVENT_FAILURE });
  }
};

export const updateEvent = (event) => async (dispatch) => {
  try {
    const data = await eventApi.updateEvent(event)
    return (
      dispatch({
        type: UPDATE_EVENT,
        message: data.message,
        dataChanged: data.dataChanged,
        event: { ...event },
      }),
      dispatch(setDataChange())
    );
  } catch (error) {
    console.log(error);
    // dispatch({type: EVENT_FAILURE})
  }
};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const data = await eventApi.deleteEvent(id)
    return (
      dispatch({
        type: DELETE_EVENT,
        message: data.message,
        dataChanged: data.dataChanged,
        deleteEvent: id,
      }),
      dispatch(setDataChange(true)),
      dispatch(getEvents())
    );
  } catch (error) {
    console.log(error);
    //  dispatch({ type: EVENT_FAILURE });
  }
};

//UTILITY
export const setDataChange = (change) => (dispatch) => {
  dispatch({
    type: SET_DATA_CHANGED,
    dataChanged: change ? true : false,
  });
};
