import React, { Component } from "react";
import { withRouter } from "react-router";
import SpotifyApi from "./spotifyApi";
import { connect } from "react-redux";
import {
  getAccessToken,
  saveSpotifyResults,
  saveUserSpotifyResults,
} from "../../../../../redux/actions/spotifyActions";
import { Field } from "redux-form";
import { required } from "../../data/Validate";
import SearchList from "./SearchList";
import "./search.css";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      song: {},
      query: {
        query: "",
        type: "track",
        limit: 5,
        offset: 0,
        market: "US",
      },
      accessToken: "",
      searchResults: [],
    };
  }

  componentDidMount() {
    const { match, getAccessToken } = this.props
    const { id } = match.params 
    getAccessToken(id);
  }

  handleSearch = (e) => {
    let copyQuery = { ...this.state.query };

    copyQuery["query"] = e.target.value.split(" ").join("+");

    this.props.token
      ? this.handleSearchResults(copyQuery, this.props.token)
      : console.log("something went wrong");
  };

  handleSearchResults = (copyQuery, token) => {
    if (copyQuery["query"] === "")
      return this.setState({ searchResults: [], query: copyQuery });
    return SpotifyApi.getSpotifyResults(copyQuery, token).then((results) => {
      console.log("spotify search results", results);
      return this.setState({
        searchResults: results,
        query: copyQuery,
      });
    });
  };

  handleSubmit = (result, fieldName) => {
    this.props.save(result);
    //TEMPORARY SOLUTION TO SAVE USER CHOICE IN STATE AND ADD IT TO RESPONSES LATER;
    this.props.saveUserSpotify({ [`${fieldName}`]: result.song });
    this.setState({ song: result });
  };

  render() {
    const { searchResults, song } = this.state;
    const { fieldName } = this.props;

    return (
      <React.Fragment>
        <Field
          name={fieldName}
          component={"input"}
          placeholder={"Search by artist, song or album..."}
          onChange={(e) => this.handleSearch(e)}
          value={song.song}
          validate={required}
        />
        <SearchList
          song={song}
          fieldName={fieldName}
          searchResults={searchResults}
          handleSearch={this.handleSearch}
          handleSubmit={this.handleSubmit}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.spotify.accessToken,
    query: state.spotify.query,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccessToken: (id) => dispatch(getAccessToken(id)),
    save: (spotify) => dispatch(saveSpotifyResults(spotify)),
    saveUserSpotify: (question) => dispatch(saveUserSpotifyResults(question)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
