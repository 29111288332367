import React from 'react'
import Events from "./Events";
import Questions from "./Questions";
import Responses from "./Responses";
import RestrictedRoute from './RestrictedRoute';
import Login from "./Login";
import { Switch, Route } from "react-router-dom";

const AdminRoutes = () => {
    return (
        <Switch>
            <Route path="/admin/events/:id/responses" render={(props)=>(
                <RestrictedRoute>
                    <Responses {...props}/>
                </RestrictedRoute>
            )}/>
            <Route path="/admin/events/:id" render={(props)=>(
                <RestrictedRoute>
                    <Questions {...props}/>
                </RestrictedRoute>
            )}/>
            <Route path="/admin/events" render={(props)=>(
                <RestrictedRoute>
                    <Events {...props}/>
                </RestrictedRoute>
            )}/>
            <Route path="/admin/login" component={Login}/>
        </Switch> 
    )
}

export default AdminRoutes