import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { deleteQuestion } from "../../../redux/actions/questionActions";
import { deleteEvent } from "../../../redux/actions/eventActions";


const Delete = props => {
  const { className, eventId, name, buttonLabel, type, id } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleDelete = () => {
    if(type === "question"){
      props.deleteQuestion(id)     
    }else{
      props.deleteEvent(eventId);
    }
    setModal(!modal);
  }

  return (
    <div>
      <button  style={buttonLabel ? styles.deleteBtn : styles.deleteQuestion} onClick={toggle}>
        Delete
      </button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          Are you sure you want to Delete this {name}?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="danger" onClick={() => handleDelete()}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const styles = {
  deleteBtn: {
    width: "10em",
    background: "red",
    padding: "0.5em",
    color: 'white',
    borderRadius: '5px',
    outline: "none",
    boxShadow: "none"
  },
  deleteQuestion: {
    width: "5em",
    background: "red",
    padding: "0.5em",
    color: 'white',
    borderRadius: '5px',
    outline: "none",
    boxShadow: "none"
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    eventId: ownProps.eventId || state.events.event.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteQuestion: (id) => dispatch(deleteQuestion(id)),
    deleteEvent: (id) => dispatch(deleteEvent(id))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Delete));

