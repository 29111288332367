import {
  LOADING,
  DATA_FAILURE,
  GET_QUESTIONNAIRE,
  GET_RESPONSES,
  UPDATE_RESPONSES,
  GET_PLAYLIST,
  ADD_PAGE,
  REMOVE_PAGE,
  REMOVE_CARD,
  ADD_RESPONSES,
  SHOW_RESULTS,
} from "../actions/types";
import { extraQuestions } from "../../helpers/questionnaire";
// import _ from "lodash";

const initialState = {
  eventId: 0,
  playlistUrl: "",
  questionnaire: [],
  playlist: [],
  responses: [],
  response: {},
  page: 0,
  last: 0,
  showResults: false,
  isLoading: false,
  defaultUser: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_QUESTIONNAIRE:
      const eventId = action.event.id;
      const data = action.questionnaire;
      const foundUser = action.user.email !== "DEFAULT@EXAMPLE.COM";
      console.log("foundUser", foundUser);

      //taking out this line bc it is breaking
      // const questionnaire = foundUser ? data : extraQuestions(data, eventId);
      const questionnaire = extraQuestions(data, eventId);

      return {
        ...state,
        isLoading: false,
        questionnaire: questionnaire,
        page: 0,
        last: data.length + 1,
        defaultUser: !foundUser,
        eventId: eventId,
      };
    case GET_RESPONSES:
      return {
        ...state,
        isLoading: false,
        responses: action.payload.sort((a, b) => a.id - b.id),
      };
    case ADD_RESPONSES:
      return {
        ...state,
        response: action.response,
      };
    case UPDATE_RESPONSES:
      const response = action.response;

      let updatedResponse = {
        ...state.responses.filter((data) => data.id === response.id)[0],
        ...response,
      };
      let copyResponses = [
        ...state.responses.filter((data) => response.id !== data.id),
        updatedResponse,
      ];
      return {
        ...state,
        responses: copyResponses.sort((a, b) => a.id - b.id),
        response: response,
      };
    case GET_PLAYLIST:
      let event = action.event.find((attr) => attr.playlistUrl);
      return {
        ...state,
        isLoading: false,
        playlist: action.playlist,
        playlistUrl: event !== undefined ? event.playlistUrl : "",
        eventId: action.event.id,
      };
    case REMOVE_CARD:
      const cards = [ ...action.playlist ];
      cards.push(cards.shift());
      return {
        ...state,
        playlist: cards,
      };
    case ADD_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case REMOVE_PAGE:
      return {
        ...state,
        page: state.page <= 0 ? 0 : state.page - 1,
      };
    case SHOW_RESULTS:
      return {
        ...state,
        showResults: true,
      };
    case DATA_FAILURE:
      return {
        eventId: localStorage.getItem("eventId"),
        questionnaire: [],
        count: 0,
        isLoading: false,
      };
    default:
      return state;
  }
}
