import React, { useState } from "react";
import QuestionForm from "../QuestionForm";
import EventForm from "../EventForm";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../../redux/actions/modalActions";
import { createUpdateQuestion } from "../../../helpers/formData";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./Modal.css"


const ModalManager = (props) => {
  const { name, title, buttonLabel, eventId, event, question } = props.modalProps; 
  const buttonColor = () => name === "question" ? styles.update : styles.edit;
  
  /**
   * Modal setup to be container propagating other modals...current renders multiple modals must fix later....
   *  const { modal } = props;
      const openModal = () => props.show(name, title, buttonLabel);
      const closeModal = () => props.hide();

    // ADD this line to Modal body when all modals are working
      { {name === "delete" ? `Are you sure you want to Delete this ${name}?` : null} }
   * 
   */

  const [modal, setModal] = useState(false);
  const toggle = () =>{
    setModal(!modal);
  } 

  return (
    <div>
      <Button style={buttonLabel === "Create" ? styles.create : buttonColor()} onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {name === "question" ? <QuestionForm question={question} eventId={eventId} buttonLabel={buttonLabel} initialValues={createUpdateQuestion(question)} toggle={toggle} /> : null}
          {name === "event" ? <EventForm buttonLabel={buttonLabel} initialValues={event}  toggle={toggle}/> : null}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};


const mapStateToProps = (state, ownProps) => {
  return {
    modal: state.modal.modal || state.modal.modalProps.open,
    name: state.modal.name || ownProps.name,
    buttonLabel: state.modal.buttonLabel ||ownProps.buttonLabel,
    title: state.modal.title || ownProps.title,
    question: state.events.event.question || ownProps.question
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    show: (name, title, buttonLabel) => dispatch(showModal(name, title, buttonLabel)),
    hide: () => dispatch(hideModal()),
  };
};

const styles = {
  create: {
    padding: "0.5em",
    width: "15em",
    height: "3em",
    margin: "5px",
    backgroundColor: "green",
    color: "white",
    borderRadius: "5px",
    outline: "none",
    boxShadow: "none"
  },
  edit: {
    width: "10em",
    background: "#00213f",
    padding: "0.5em",
    color: "white",
    borderRadius: "5px",
    outline: "none",
    boxShadow: "none"
  },
  update: {
    backgroundColor: "##007aff",
    outline: "none",
    boxShadow: "none"
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
