import React, { Component } from 'react';
import Dashboard from "../Dashboard";
import Questions from "./Questions";
import { connect } from "react-redux";
import { setDataChange } from "../../../redux/actions/questionActions";
import { getEvent } from "../../../redux/actions/eventActions";

 class QuestionContainer extends Component {
     componentDidMount(){
        const { match, getEvent } = this.props
        const { id } = match.params
        getEvent(id);
     }

     componentDidUpdate(){
        const { match, dataChanged, setDataChange } = this.props
        const { id } = match.params

        if (dataChanged) {
          getEvent(id);
          return setDataChange();
        }
      }

    render() {
        const { event, questions, Navbar } = this.props;
        const {id, name} = event

        const tableHeaders =  ["Question", "Type", "Edit", "Delete"];
        const tableData =  questions ? questions.sort((a,b) => a.id - b.id) : questions;
        
        return (
            <React.Fragment>
                <Navbar showResponses={true} event={event}/>
                <Dashboard component={"Questions"} eventId={id} eventName={name}/>
                <Questions tableData={tableData} tableHeaders={tableHeaders} eventId={id} eventName={name}/>
            </React.Fragment>
        )  
    }
  }
  const mapStateToProps = state => {
    return {
      event: state.events.event,
      questions: state.events.event.questions,
      dataChanged: state.questions.dataChanged
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      setDataChange: () => dispatch(setDataChange()),
      getEvent: (id) => dispatch(getEvent(id))
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);
