export const fetchAccessToken = async () => {
    const response = await fetch("/api/getTokens"); 
    return await response.json();
}

export const createSpotify = async (spotify) => {
    const response = await fetch("/admin/spotifies", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(spotify),
      });
      return await response.json();
}

export const updateSpotify = async (spotify) => {
    const { responseId } = spotify;
    const response = await fetch(`/admin/spotifies/${responseId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(spotify),
    });
    return await response.json();
}