import { SHOW_MODAL, HIDE_MODAL } from "./types";

export const showModal = (name, title, buttonLabel) => dispatch => {
    dispatch({
        type: SHOW_MODAL,
        open: true,
        name, title, buttonLabel
    })
}

export const hideModal = () => dispatch => {
    dispatch({
        type: HIDE_MODAL
    })
}