import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'
import "./Navbar.css";

const Nav = props => {
  const { event, showResponses, showQuestions, authorized } = props;
  const { id, name } = event || {}
  const loggedIn = authorized ? "Logout" : "Login";
  
  return (
      <div className="sp-main-nav">
        <div className="sp-main-nav-left">
          <FontAwesomeIcon icon={faSpotify} />
          <Link to={'/admin/events/'}>
            <h1> Admin </h1>
          </Link>
        </div>
        <div className="sp-main-nav-middle">
            <h3>
              {name}
            </h3>
            {showResponses ? (
              <Link to={`/admin/events/${id}/responses`} className={"sp-main-nav-right-link"} >
                Response Data
              </Link>
            ) : null}
            {showQuestions ? (
              <Link to={`/admin/events/${id}/`} className={"sp-main-nav-right-link"} >
                Questions
              </Link>
            ) : null}
        </div>
        <div className="sp-main-nav-right">
          <Link
            replace
            to="/admin/login"
            onClick={() => localStorage.setItem("sp-access", null)}
          >
            {loggedIn}
          </Link>
        </div>
      </div>
  );
};


export default Nav;
