import React from "react"
import { IconButton, Form } from '@blueprintnyc/bpnyc-common';
import { addEvent } from 'redux/actions/eventActions'
import { connect } from 'react-redux'

const AddEventButton = (props) => {
    const { createEvent, updateState } = props

    const handleSubmit = (data) => {
       createEvent(data)
       updateState(["showModal", "content"],[false, null])
    };
    
    const modalContent = <>
        <h3>Create Event</h3>
        <Form onFinish={handleSubmit}>
            <Form.Item label="Name" name="name">
                <Form.Input />
            </Form.Item>
            <IconButton 
                type="primary" 
                htmlType="submit" 
                color="#0D62A5"
            > Save </IconButton>
        </Form>
    </>
  
    return(
        <IconButton     
            className="sp-admin-events-container-add-event-button" 
            styleOW={{}} 
            onClick={()=>{updateState(["showModal", "content"],[true, modalContent])}}
            color="#808080"
            icon="plusCircle" 
        />
    )
}

const msp = () => {}

const mdp = (dispatch) => ({
    createEvent: (data) => dispatch(addEvent(data))
})

export default connect(msp, mdp)(AddEventButton);
