// ADMIN LOGIN DATA
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGGING_FAILURE = "LOGGING_FAILURE";

// USER DATA
export const ADD_USER = "ADD_USER";
export const GET_USER = "GET_USER";
export const USER_FAILURE = "USER_FAILURE";

// EVENT DATA
export const GET_EVENT = 'GET_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const EVENT_FAILURE = "EVENT_FAILURE";
export const EVENT_LOADING = "EVENT_LOADING"; 

// APP MANAGEMENT DATA
export const LOADING = "LOADING";
export const ADD_PAGE= "ADD_PAGE";
export const REMOVE_PAGE= "REMOVE_PAGE";
export const SET_DATA_CHANGED = "SET_DATA_CHANGED";
export const GET_PLAYLIST = "GET_PLAYLIST";
export const REMOVE_CARD = "REMOVE_CARD";
export const GET_RESPONSES = "GET_RESPONSES";
export const ADD_RESPONSES = "ADD_RESPONSES";
export const UPDATE_RESPONSES = "UPDATE_RESPONSES";
export const SHOW_RESULTS = "SHOW_RESULTS";
export const GET_QUESTIONNAIRE = "GET_QUESTIONNAIRE";
export const DATA_FAILURE = "DATA_FAILURE";

//QUESTION 
export const GET_QUESTION = 'GET_QUESTION';
export const ADD_QUESTION = 'ADD_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const QUESTION_LOADING = "QUESTION_LOADING";
export const QUESTION_FAILURE = 'QUESTION_FAILURE';

//CHOICES 
export const ADD_CHOICES = 'ADD_CHOICES';
export const UPDATE_CHOICE = 'UPDATE_CHOICE';
export const DELETE_CHOICE = 'DELETE_CHOICE';
export const CHOICES_FAILURE = "CHOICES_FAILURE";

// ERRORS 
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//MODAL
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

//SPOTIFY FETCHING_TOKEN,
export const FETCHING_TOKEN = "FETCHING_TOKEN";
export const GET_ACCESS_TOKEN = "GET_ACCESS_TOKEN";
export const SAVE_SPOTIFY = "STORE_SPOTIFY";
export const SAVE_USER_RESULTS = "SAVE_USER_RESULTS";
export const ADD_SPOTIFY = "ADD_SPOTIFY";
export const UPDATE_SPOTIFY = "UPDATE_SPOTIFY";
export const SEARCH_VALUE = "SEARCH_VALUE";








