import {
  GET_QUESTIONNAIRE,
  ADD_PAGE,
  REMOVE_PAGE,
  GET_RESPONSES,
  ADD_RESPONSES,
  UPDATE_RESPONSES,
  GET_PLAYLIST,
  REMOVE_CARD,
  SHOW_RESULTS,
  DATA_FAILURE,
} from "./types";

import * as appApi from "../api/app.api"
import { createUpdatedResponseObject } from "../../helpers/response";
import { updateSpotify, createSpotify } from "./spotifyActions";

// QUESTIONNAIRE APP DATA
export const getQuestionnaire = (id) => async(dispatch, getState) => {
  //CHECK FOR ID
  if (!id) return dispatch({ type: DATA_FAILURE });

  try {
    const data = await appApi.fetchQuestionaire(id)
    dispatch({
      type: GET_QUESTIONNAIRE,
      questionnaire: data.questionnaire,
      event: data.event,
      user: getState().users.user,
    });
  } catch (error) {
    // dispatch(error);
    // dispatch({ type: DATA_FAILURE });
    console.log(error);
  }
};

export const addPage = () => (dispatch) => {
  dispatch({ type: ADD_PAGE });
};

export const removePage = () => (dispatch) => {
  dispatch({ type: REMOVE_PAGE });
};

// ADMIN TABLE DATA
export const getResponses = (id) => async (dispatch) => {
  const data = await appApi.fetchResponses(id)
  dispatch({
    type: GET_RESPONSES,
    payload: data.response,
  });
};

export const addResponses = (responses) => async (dispatch) => {
  const { response } = responses;
  let eventId = parseInt(response.eventId);

  try {
    const data = await appApi.createResponse(eventId, response)
    //ADD RESPONSE ID TO NEWLEY CREATED SPOTIFY SONG
    const spotify = { ...responses.spotifySong, responseId: data.response.id };

    return (
      dispatch({
        type: ADD_RESPONSES,
        payload: data,
      }),
      dispatch(createSpotify(spotify)),
      dispatch(showResults())
    );
  } catch (error) {
    dispatch(error);
    dispatch({ type: DATA_FAILURE });
  }
};

export const updateResponses = (response) => async (dispatch) => {
  const spotify = createUpdatedResponseObject(response);

  try {
    const data = await appApi.updateResponse(response)
    return (
      dispatch({
        type: UPDATE_RESPONSES,
        payload: data,
        response: response,
      }),
      dispatch(updateSpotify(spotify))
    );
  } catch (error) {
    // dispatch(error);
    // dispatch({ type: DATA_FAILURE });
    console.log(error);
  }
};

//UTILITY
export const showResults = () => (dispatch) => {
  dispatch({ type: SHOW_RESULTS });
};

/// PLAYLIST APP DATA
export const getPlaylist = (id) => async (dispatch) => {
  const data = await appApi.fetchPlaylist(id)
  dispatch({
    type: GET_PLAYLIST,
    playlist: data.spotifies.sort(() => Math.random() - 0.5),
    event: data.event,
  });
};

export const removePlaylistCard = (playlist) => (dispatch) => {
  dispatch({
    type: REMOVE_CARD,
    playlist: playlist,
  });
};
