import React from 'react';
import { CSVLink } from 'react-csv';
import { FaFileDownload } from "react-icons/fa";
import { sampleHeaders, sampleRows } from "../../../helpers/csv";
import { csvData } from "../../../helpers/response";

const CSV = ({responses, questionnaire, eventName}) => {
    const questionnaireLength = questionnaire.length > 0;
    const responsesLength = responses.length > 0;

    //error handle for undefined variables
    const tableHeaders = responsesLength && questionnaireLength ? csvData(responses, questionnaire).tableHeaders : sampleHeaders;
    const tableRows = responsesLength && questionnaireLength  ? csvData(responses, questionnaire).allData : sampleRows;
    const fileName = eventName ? eventName + "-.csv" : "Questionaire.csv";

    return (
      <CSVLink style={styles.link} data={tableRows} headers={tableHeaders } filename={fileName}>
        {"Export "} <FaFileDownload />
      </CSVLink>
    );
}

const styles = {
    link: {
        color: "white",
        textDecoration: "none"
    }
}
export default CSV;
