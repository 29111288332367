import React from 'react'
import Playlist from './playlist'
import Authenticate from "./Authenticate"
import { Switch, Route } from "react-router-dom";


const AdminRoutes = () => {
    return (
        <Switch>
            <Route path="/attendee/events/:id/playlist" component={Playlist}/>
            <Route path="/attendee/events/:id/questionnaire" component={Authenticate}/>
        </Switch> 
    )
}

export default AdminRoutes
