import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import eventReducer from "./eventReducer";
import questionReducer from "./questionReducer";
import userReducer from "./userReducer";
import appReducer from "./appReducer";
import modalReducer from "./modalReducer";
import spotifyReducer from "./spotifyReducer";

const rootReducer = combineReducers({
    form: formReducer,
    events: eventReducer,
    questions: questionReducer,
    users: userReducer,
    app: appReducer,
    spotify: spotifyReducer,
    modal: modalReducer
});

export default rootReducer;