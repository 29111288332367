import React from "react";

const ResultsPage = (props) => {
  return (
    <div id="resultsPageDiv">
      <h1>Thank you!</h1>
      <h1>
      "Thank you! We'll review your song and add to the playlist!"
      </h1>
    </div>
  );
};

export default ResultsPage;
