import React from 'react';
import {  Field } from "redux-form";
import { FaTimesCircle } from "react-icons/fa";
import {connect } from "react-redux";
import { deleteChoice } from "../../../redux/actions/questionActions";

const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <div>
        <input
          style={{ textAlign: "center" }}
          {...input}
          type={type}
          placeholder={label}
        />
      </div>
    </div>
);


const Choices = (props) => {
  const { fields, question, toggle } = props;
  
  
  const handleDelete = (index) => {
    fields.remove(index);

    // CHECK TO SEE IF NEED TO DELETE FROM BACKEND
    if (!question) return;
    
    let id = question.choices[index].id;
    props.deleteChoice(id, question.id);
    return toggle()
  };


  return (
    <div>
      <button
        style={styles.addOptionBtn}
        type="button"
        onClick={() => fields.push()}
      >
        Add Option
      </button>
      {fields.map((option, index) => (
        <div key={index} style={styles.choiceContainer}>
          <div style={styles.listItem}>
            <Field
              name={option}
              type="text"
              component={renderField}
              label={`Option #${index + 1}`}
            />
            <button
              type="button"
              title="Remove Option"
              onClick={() => handleDelete(index, option)}
              style={styles.deleteItem}
            >
              <FaTimesCircle />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  addOptionBtn: {
    width: "20em",
    backgroundColor: "green",
    borderRadius: "5px",
    padding: "0.5em",
    color: "white",
    margin: "5px",
  },
  choiceContainer: {
    display: "flex",
    justifyContent: "center",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
  deleteItem: {
    display: "flex",
    backgroundColor: " red",
    margin: "5px",
    width: "3vw",
    height: "3vh",
    borderRadius: "5px",
    fontSize: "1em",
  },
  radioTextMessage: {
    textAlign: "center",
  },
};

const mapDispatchToState = (dispatch) => {
  return{
    deleteChoice: (id, question) => {dispatch(deleteChoice(id, question))}
  }
}

export default connect(null, mapDispatchToState)(Choices);
