import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Radio,
} from "@material-ui/core";
import "./search.css";

const SearchList = ({ searchResults, song, handleSubmit, fieldName }) => {
  console.log("here", searchResults);
  return (
    <React.Fragment>
      {searchResults !== undefined ? (
        <div
          id="searchContainer"
          style={{ backgroundColor: "rgba(0,0,0,0.15" }}
        >
          {searchResults.map((result) => {
            return (
              <ListItem
                id="searchItemContainer"
                key={result.id}
                button
                onClick={() => handleSubmit(result, fieldName)}
              >
                <ListItemAvatar>
                  <Avatar alt={"album cover"} src={result.albumUrl} />
                </ListItemAvatar>
                <div id="searchItemText">
                  <p id="song">{result.song}</p>
                  <p id="artist">{result.artist}</p>
                </div>
                <div id="searchItemRadioDiv">
                  <Radio
                    type="radio"
                    edge="end"
                    name="search"
                    checked={result.id === song.id ? true : false}
                  />
                </div>
              </ListItem>
            );
          })}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default SearchList;
