import React from 'react'
import './index.css'

const Unauthorized = (props) => {
    const { Navbar } = props
    return (
        <>
            <Navbar/>
            <div className="unauthorized">
                <h1>Access Denied</h1>
                <p>Please log in.</p>
            </div>
        </>
    )
}

export default Unauthorized
