export const fetchQuestion = async (id) => {
    const response = await fetch(`/admin/questions/${id}`);
    return await response.json();
}

export const createQuestion = async (question) => {
    const response = await fetch("/admin/questions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(question),
      });
      return await response.json();
} 

export const updateQuestion = async (id, question) => {
    const response = await fetch(`/admin/questions/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(question),
      });
      return await response.json();
}

export const deleteQuestion = async (id) => {
    const response = await fetch(`/admin/questions/${id}`, {
        method: "DELETE",
    });
    return await response.json();
}

export const createChoices = async (questionId, choice) => {
    const response = await fetch(`/admin/questions/${questionId}/choices`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(choice),
    });
    return await response.json();
}

export const updateChoices = async (choice) => {
    const response = await fetch(`/admin/choices/${choice.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(choice),
    });
    return await response.json();
}

export const deleteChoice = async (id) => {
    const response = await fetch(`/admin/choices/${id}`, {
        method: "DELETE",
    });
    return await response.json();
}