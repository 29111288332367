import React from "react";
import { Table } from "reactstrap";
import EditQuestion from "../Modals";
import Delete from "../Modals/Delete";


const Questions = (props) => {
  const { tableData, tableHeaders } = props;

  if (!tableData) {
    return <div style={{ textAlign: 'center' }}>"No Questions Created Yet"</div>
  } else {
    return (
      <div>
        <Table style={styles.table}>
          <thead>
            <tr>
              {tableHeaders.map((header, idx) => {
                return <th key={idx}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((question) => {
              return (
                <tr key={question.id}>
                  <td>{question.question}</td>
                  <td>{question.inputName}</td>
                  <td>
                    <EditQuestion id={question.id} name={"question"} question={question} title={"Update the Question"} buttonLabel={"Update"} questionId={question.id} />
                  </td>
                  <td>
                    <Delete type={"question"} id={question.id} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
};

const styles = {
  card: {
    top: "4em",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: "50vw",
    height: "30vh",
    margin: "0 auto",
    marginRight: "5em",
  },
  table: {
    width: "95vw",
    margin: "0 auto",
    padding: "1em",
    marginTop: "1em",
  }
};

export default Questions;

