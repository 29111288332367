import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Card } from "reactstrap";
import { getQuestionnaire, addResponses } from "redux/actions/appActions";
import { getEvent } from "redux/actions/eventActions";
import { addUser } from "redux/actions/userActions";
import { createResult } from "./data/formData";
import QuestionnaireManager from "./QuestionnaireManager";
import ResultsPage from "./ResultsPage";

class QuestionnaireContainer extends Component {
  async componentDidMount() {
    const { match, getEvent, getQuestionnaire } = this.props
    const { id } = match.params

    await getEvent(id);
    await getQuestionnaire(id);
  }

  //SUBMIT USER RESPONSES
  results = (values) => {
    const { defaultUser, user, questionnaire, spotify, match } = this.props;
    const { id } = match.params
    console.log(values)
    const responses = createResult(
      values,
      id,
      user,
      spotify,
      defaultUser,
      questionnaire
    );

    if (defaultUser) {
      return this.props.addUser(responses);
    } else {
      return this.props.addResponses(responses);
    }
  };

  render() {
    const { page, questionnaire, event, showResults } = this.props;
    const textColor = event.textColor || "#000";

    return (
      <Card
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: event.background || "#fff",
          color: event.textColor || "#000",
        }}
      >
        {questionnaire.length > 0 && !showResults ? (
          <QuestionnaireManager
            question={questionnaire[page]}
            onSubmit={this.results}
            textColor={textColor}
          />
        ) : null}
        {showResults ? <ResultsPage /> : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    event: state.events.event,
    questionnaire: state.app.questionnaire,
    page: state.app.page,
    showResults: state.app.showResults,
    user: state.users.user,
    defaultUser: state.app.defaultUser,
    // NECESSARY TO MANAGE THE USER SONG INPUT WITH REPONSES, NEED TO REFACTOR
    spotify: state.spotify,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuestionnaire: (id) => dispatch(getQuestionnaire(id)),
    getEvent: (id) => dispatch(getEvent(id)),
    addResponses: (values) => dispatch(addResponses(values)),
    addUser: (values) => dispatch(addUser(values)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuestionnaireContainer)
);
