export const fetchQuestionaire = async (id) => {
    const response = await fetch(`/admin/events/${id}/questionnaire`);
    return await response.json();
}

export const fetchResponses = async (id) => {
    const response = await fetch(`/admin/events/${id}/responses`);
    return await response.json();
}

export const createResponse = async (eventId, response) => {
    const resp = await fetch(`/admin/events/${eventId}/responses`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(response),
      });
      return await resp.json();
}

export const updateResponse = async (response) => {
    const resp = await fetch(`/admin/responses/${response.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(response),
      });
      return await resp.json();
}

export const fetchPlaylist = async (id) => {
    const response = await fetch(`/api/events/${id}/playlist/reviewed`);
    return await response.json();
}