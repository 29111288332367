import _ from "lodash";

export const createResult = (results, id, user, spotify, defaultUser, questionnaire) => {
    //PARSE DATA TO CREATE OBJECTS TO BE CREATED
    const questions = questionnaire.map((question) => question.question);
    const responses = Object.values(results);

    //EXTRACT SPOTIFY FIELDS FOR SPOTIFY CARD
    const reason = createReason(questionnaire, results);

    //FUNCTIONS CREATING THREE OBJECTS
    const newUser = defaultUser ? createUser({ id: 0, name: responses[0], email: responses[1], eventId: id }) : user;
    const response = createResponse(results, newUser, questions, spotify.questions);
    const spotifySong = createSpotify(newUser, reason, spotify);

    return {response, newUser, spotifySong} 
}


const createReason = (questionnaire, results) => {
  let reason =  questionnaire.find((question) => question.inputName === "reason");
  console.log(results)
  console.log(reason)
  if (!reason || !reason.question) return "";
  console.log(reason.question)
  console.log(results[reason.question])
  const question = reason.question.slice(0, 50);
  if( !question || !results[question] ) return "";
  return results[question].toString();
}

export const createResponse = (results, user, questions, spotify ) => { 

    // REPLACE QUERY VALUE WITH USER RESPONSE VALUE 
    if(spotify.length > 0){
        let key = Object.keys(spotify[0]);
        let value = Object.values(spotify[0]);
        results[key] = value.toString();
    };

    //PARSE RESULTS FOR STORAGE
   let userResponses = Object.values(results).map(result => {
        let final;
            if(_.isString(result)){
                final = result;
            }else if(_.isArray(result)){
                final = _.map(result, "value");
                return _.join(final, ",");
            }else{
                final = result.value;
            }
        return final;
    });

    // CREATE RESPONSE OBJECT WITH ALL ATTRIBUTES
    const response = {
        reviewed: "0",
        name: user.name,
        email: user.email,
        completed: "1",
        questionnaire: questions,
        responses: userResponses,
        userId: parseInt(user.id),
        eventId: parseInt(user.eventId) 
    }
    return response;
}

export const createUser = ({id, name, email, eventId}) => {
   return {id, name, email, eventId }
}


export const createSpotify = (user, reason, spotify) => {
    const {song: { uri, id, song, album, albumUrl, artist, url}} = spotify;
    
    if(!spotify.spotify) return false;

    const newSpotify = {
      uri,
      songId: id,
      song,
      album,
      artist,
      url,
      imageUrl: albumUrl,
      reason: reason,
      person: user.name,
      eventId: parseInt(user.eventId),
      responseId: 0
    };
    return newSpotify;
}