import React from "react";
import { reduxForm } from "redux-form";
import FormFields from "./Fields/FormFields";
import SearchField from "./Fields/search";

//Handle Final Page Data
const SubmissionPage = (props) => {
  const {
    previousPage,
    handleSubmit,
    pristine,
    submitting,
    question: { inputType, question, choices, inputName },
    textColor,
  } = props;
  const fieldName = question.length > 50 ? question.slice(0, 50) : question; // ??? UNKNOWN UNKNOWN

  //CHECKING FOR INPUT SEARCH AND REASON BECAUSE GOING BACK CAUSES A FETCHING ISSUE WITH SPOTIFY, NEED TO FIX
  const showButton =
    inputName !== "search" && inputName !== "reason" ? true : false;

  return (
    <form onSubmit={handleSubmit}>
      <div className="questionAndInputDiv">
        <h1>{question}</h1>
        <div className="questionnaireInput">
          {inputName === "search" || inputName === "track" ? (
            <SearchField
              inputType={inputType}
              inputName={inputName}
              fieldName={fieldName}
              choices={choices}
            />
          ) : (
            <FormFields
              inputType={inputType}
              inputName={inputName}
              fieldName={fieldName}
              choices={choices}
            />
          )}
        </div>
      </div>
      <div className="questionnaireBtnContainer">
        {showButton ? (
          <button
            style={{
              color: textColor,
              marginRight: "1vw",
            }}
            type="submit"
            disabled={pristine || submitting}
            onClick={previousPage}
          >
            Prev
          </button>
        ) : null}

        <button
          style={{
            color: textColor,
          }}
          type="submit"
          disabled={pristine || submitting}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "questionnaire", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  // validate, // <--- validation function given to redux-form
})(SubmissionPage);
