// CREATE QUESTION
export const createQuestion = (question, eventId, name, id) => {
  return [
    {
      id: id,
      question: question,
      inputType: name === "email" ? "email" : "text",
      inputName: name,
      spotify: false,
      order: 0,
      choices: [],
      eventId: eventId,
    },
  ];
};

// EXTRA QUESTIONS TO CREATE
export const extraQuestions = (questionnaire, id) => {
  let copyQuestionnaire = [
    ...createQuestion("What is your full name?", id, "name", 1),
    ...createQuestion("What is your email address?", id, "email", 2),
    ...questionnaire,
  ];
  copyQuestionnaire.map((question, idx) => (question.order = idx + 1));
  return copyQuestionnaire;
};
