import React, { Component } from "react";
import {Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { List, ListItem} from '@material-ui/core';

class Home extends Component {
  render(){
       return (
     <React.Fragment>
          <Card style={styles.container} >
         <h1 style={styles.header} >Welcome to BlueprintNYC Spotify app</h1>
         <List>
           <ListItem>
             Administration Dashboard:
             <Link to={"/admin/events"}><span style={styles.spacer} >Admin Panel</span></Link>
           </ListItem>
           <ListItem>
             Default Questionnaire:
             <Link to={"attendee/events/1/questionnaire"}><span style={styles.spacer} >Questionnaire</span></Link>
           </ListItem>
           <ListItem>
             Default Playlist:
             <Link to={"attendee/events/1/playlist"}><span style={styles.spacer}>Playlist</span></Link>
           </ListItem>
         </List>
       </Card>
     </React.Fragment>
   );  
  }
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
},
spotifyContainer: {
    display: "flex",
    justifyContent: "space-evenly",
},
homeBtn: {
    padding: "5px",
    margin: "0px 5px 0px 5px"
},
btnGroup: {
    display: "flex"
},
header: {
    marginTop: "1em",
    marginBottom: "0.5em",
},
spacer: {
    marginLeft: ".5em",
},
spotify: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
}
}
export default Home;
