import {
  GET_EVENT, 
  GET_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  EVENT_LOADING,
  EVENT_FAILURE,
  SET_DATA_CHANGED,
  ADD_QUESTION
} from "../actions/types";

const initialState = {
  eventId: localStorage.getItem("eventId"),
  isLoading: false,
  events: [],
  dataChanged: false,
  event: {
    id: "",
    name: "",
    background: "#fff",
    textColor: "#000"
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EVENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_QUESTION: 
      const stateDup = { ...state }
      stateDup.event.questions.push(action.question)
      return stateDup
    case GET_EVENT:
      return {
        ...state,
        isLoading: false,
        event: { ...action.event },
        message: action.message,
        eventId: action.eventId
      };
    case GET_EVENTS:
      return {
        ...state,
        events: action.events.sort((a,b) => a.id -b.id),
        isLoading: false,
        message: action.message
      };
    case ADD_EVENT:
      return {
        ...state,
        event: action.event,
        message: action.message,
        dataChanged: action.dataChanged,
      };
    case UPDATE_EVENT:
      const event = action.event;
      return {
        ...state,
        event: event,
        message: action.message,
        dataChanged: action.dataChanged,
        events: [...state.events
          .filter(data => data.id !== event.id), event]
          .sort((a,b) => a.id - b.id)
      };
    case DELETE_EVENT:
      const id = action.deletedEvent;

      return {
        ...state,
        events: state.events
          .filter((data) => data.id !== id)
          .sort((a,b) => a.id - b.id),
        message: action.message,
        dataChanged: action.dataChanged,
      };
    case EVENT_FAILURE:
      return {
        eventId: "",
        isLoading: false,
        dataChanged: false,
        events: [],
        event: {
          id: "",
          name: "",
          background: "#fff",
          textColor: "#000",
        },
      };
    case SET_DATA_CHANGED:
        return {
          ...state,
          dataChanged: action.dataChanged
        };
    default:
      return state;
  }
}
