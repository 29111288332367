import React from "react";

export const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div>
    {label ? <label style={styles.label}>{label}</label> : null}

    <input
      style={styles.inputField}
      {...input}
      placeholder={label}
      type={type}
    />
    {touched && error && <span style={styles.error}>{error}</span>}
  </div>
);

const styles = {
  label: {
    fontSize: "1.5em",
  },
  error: {
    color: "red",
    fontweight: "bold",
    fontSize: "1em",
  },
};
export default renderField;
