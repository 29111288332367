import React from "react";
import { Field } from "redux-form";
import { renderSelectList } from "./renderSelectList";
import { renderField } from "./renderField";
import { renderMultiselect } from "./renderMultiSelect";
import { email, required } from "../data/Validate";

// FIELDS OF FORM
const FormFields = ({ inputType, fieldName, choices }) => {
  return (
    <React.Fragment>
      {inputType === "text" ? (
        <Field name={fieldName} component={renderField} validate={required} />
      ) : null}
      {inputType === "email" ? (
        <Field
          name={"email"}
          component={renderField}
          validate={[email, required]}
        />
      ) : null}
      {inputType === "select" ? (
        <div style={styles.questionContainer}>
          <Field
            name={fieldName}
            options={choices.map((choice) => ({
              value: choice.name,
              label: choice.name,
            }))}
            component={renderSelectList}
            validate={required}
          />
        </div>
      ) : null}
      {inputType === "multi-select" ? (
        <Field
          name={fieldName}
          options={choices.map((choice) => ({
            value: choice.name,
            label: choice.name,
          }))}
          component={renderMultiselect}
          multi
          validate={required}
        />
      ) : null}
    </React.Fragment>
  );
};

const styles = {
  questionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

export default FormFields;
