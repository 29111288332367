export const sampleHeaders = [
    {label: "Name", key: "name"},
    {label: "Email", key: "email"},
    {label: "Reviewed", key: "reviewed"},
]

export const sampleRows  = [
    {name: "Apple Sauce", email: "a@a.com", reviewed: true},
    {name: "Banana Pudding", email: "b@b.com", reviewed: true},
    {name: "Cherry Pie", email: "c@c.com", reviewed: true},
]