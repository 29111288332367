import React from 'react';
import {  Button } from "reactstrap";
import { reduxForm, FieldArray, Field, formValueSelector } from "redux-form";
import { addQuestion, updateQuestion, addChoices } from "../../../redux/actions/questionActions";
import Choices from "../Choices/Choices";
import { connect } from 'react-redux';
import { radioArray, createQuestion, parseUpdatedQuestion} from "../../../helpers/formData";
import { FormGroup } from "reactstrap";

import './QuestionForm.css'

let QuestionForm = props => {
  const { radioValue, handleSubmit, pristine, reset, submitting, eventId, buttonLabel, question, toggle} = props;
  let inputType;

 
  if(radioValue){
    inputType = radioValue.toLowerCase().split(" ")[1];
  }else{
    inputType = "text";
  }

  const submit = (values) => {
    let newQuestion;

    if(buttonLabel === "Create"){
      newQuestion = createQuestion(values, eventId);
      props.addQuestion(newQuestion);
    }else{
      const { id } = question;
      const {updatedQuestion, updatedChoices} =  parseUpdatedQuestion(question, values);
      props.updateQuestion(id, updatedQuestion, updatedChoices);
    }
  };

  return (
    <form style={styles.container} onSubmit={handleSubmit(submit)}>
      <div style={styles.radioButtons}>
        {radioArray.map((data) => {
          return (
            <label key={data.value}>
              <Field
                name={data.name}
                component={data.component}
                type={data.type}
                value={data.value}
                style={{ margin: "5px" }}
            
              />
              {data.value}
            </label>
          );
        })}
      </div>
      <div>
        <div>
          <Field style={styles.textArea} name="question" component="textarea"/>
        </div>
      </div>
      {inputType === "select" ? (
        <div style={styles.mulitpleChoice}>
          <div>
            <Field
              name="multiselect"
              component="input"
              type="checkbox"
              style={{ margin: "5px" }}
            />
          </div>
          <label>Multiple Selections</label>
        </div>
      ) : null}
      <div>
        {inputType === "text" ? (
          <FormGroup>
            <p className="radioTextMessage">
              {radioArray.find((data) => data.util === "text").text}
            </p>
          </FormGroup>
        ) : (
          <FormGroup>
            <p className="radioTextMessage">
              {radioArray.find((data) => data.util === inputType).text}
            </p>
          </FormGroup>
        )}
      </div>
      {inputType === "select" ? (
        <FieldArray name="choices" question={question} toggle={toggle} component={Choices} />
      ) : null}
      <div>
        <Button style={styles.button} color="success" type="submit" disabled={ pristine || submitting } onClick={toggle}> Submit </Button>
        <Button style={styles.button} color="secondary" type="button" disabled={ pristine || submitting} onClick={reset}> Clear Values </Button>
      </div>
    </form>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "40vw !important",
    justifyContent: "center",
    alignItems: "center",
  },
  createQuestion: {
    padding: "0.5em",
    width: "15em",
    height: "3em",
    margin: "5px",
    backgroundColor: "green",
    color: "white",
    borderRadius: "5px",
  },
  radioButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "35em",
    marginLeft: "1.5em",
  },
  textArea: {
    width: "25em",
    height: "10vh",
    padding: "0.8em"
  },
  mulitpleChoice: {
    display: "flex",
    flexWrap: "wrap",
    width: "25em",
    margin: "0 5.5em",
  },
  button: {
    margin: "15px",
  }
}


const mapDispatchToProps = (dispatch) => {
  return{
    addQuestion: (question) => dispatch(addQuestion(question)),
    updateQuestion: (id, question, choices) => dispatch(updateQuestion(id, question, choices)),
    addChoices: (choices) => dispatch(addChoices(choices))
  }
}

const selector = formValueSelector('questionForm');

QuestionForm = connect((state, ownProps) => {
  const radioValue = selector(state, 'radio')
  return { radioValue, initialValues: ownProps.initialValues }}, mapDispatchToProps)(QuestionForm)

QuestionForm = reduxForm({
  form: "questionForm",
  enableReinitialize: true,
})(QuestionForm);

export default QuestionForm;


