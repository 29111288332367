import React, { Component } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux"
import { getEvent } from "../../../redux/actions/eventActions";
import { getQuestion } from "../../../redux/actions/questionActions";
import ModalManager from "./ModalManager";


class Modals extends Component {
    render() {
        const { name, title, question, event, buttonLabel, match } = this.props;
        const { id } = match.params
    
        return <ModalManager modalProps={{ name, title, buttonLabel, question, event, eventId: id }} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    getEvent: id => dispatch(getEvent(id)),
    getQuestion: id => dispatch(getQuestion(id))
})

export default withRouter(connect(null, mapDispatchToProps)(Modals));



