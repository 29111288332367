import {
  GET_QUESTION,
  ADD_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  ADD_CHOICES,
  UPDATE_CHOICE,
  DELETE_CHOICE,
  SET_DATA_CHANGED,
} from "../actions/types";

const initialState = {
  questions: [],
  question: {},
  choices: [],
  dataChanged: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_QUESTION:
      return {
        ...state,
        question: action.question,
      };
    case ADD_QUESTION:
      return {
        ...state,
        question: action.question,
        choices: action.choices,
        dataChanged: action.dataChanged,
      };
    case UPDATE_QUESTION:
      return {
        ...state,
        question: action.question,
        questions:  action.questions,
        dataChanged: action.dataChanged,
      };
    case DELETE_QUESTION:
      return {
        ...state,
        question: {},
        dataChanged: action.dataChanged,
        questions: action.questions,
      };
    case ADD_CHOICES:
      const newChoices = [];
      return {
        ...state,
        choices: newChoices.push(action.choice),
        question: {...action.question, choices: newChoices},
        dataChanged: action.dataChanged,
      };
    case UPDATE_CHOICE:
      const updatedChoices = [];
      return {
        ...state,
        choices: updatedChoices.push(action.choice),
        question: { ...state.question, ...updatedChoices },
        dataChanged: action.dataChanged,
      };
    case DELETE_CHOICE:
      const choice_id = action.id;
      const remainingChoices = action.question.choices;
  
      return {
        ...state,
        choices: remainingChoices.map(choice => choice.id !== choice_id),
        question: {...action.question, choices: remainingChoices},
        dataChanged: action.dataChanged
      };
    case SET_DATA_CHANGED:
      return {
        ...state,
        dataChanged: action.dataChanged,
      };
    default:
      return state;
  }
}
