import {
  FETCHING_TOKEN,
  DATA_FAILURE,
  GET_ACCESS_TOKEN,
  SAVE_SPOTIFY,
  SAVE_USER_RESULTS,
  ADD_SPOTIFY,
  UPDATE_SPOTIFY,
  SEARCH_VALUE,
} from "../actions/types";

const initialState = {
    isloading: false,
    query: "",
    type: "track",
    limit: 5,
    offset: 0,
    market: "US",
    accessToken: "",
    searchResults: [],
    questions: [],
    spotify: false,
    song: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_TOKEN:
      return {
        ...state,
        isLoading: true
      };
    case GET_ACCESS_TOKEN:
      return {
        ...state,
        isLoading: false,
        accessToken: action.accessToken,
      };
    case SEARCH_VALUE:
      return {
        ...state,
        query: action.query,
      };
    case SAVE_SPOTIFY:
      return {
        ...state,
        song: action.song,
        spotify: action.spotify
      }
    case SAVE_USER_RESULTS:
      return {
        ...state,
        questions: [...state.questions, action.question]
      }
    case ADD_SPOTIFY:
      return {
        ...state,
        song: action.song
      };
    case UPDATE_SPOTIFY:
      return {
        ...state,
        message: action.payload
      };
    case DATA_FAILURE:
      return {
        isloading: false,
        accessToken: "",
        searchResults: [],
        eventId: 0,
      };
    default:
      return state;
  }
}
