import React, { Component } from 'react';
import EventCard from "./EventCard";
import {  Modal } from '@blueprintnyc/bpnyc-common';
import { getEvents, setDataChange} from "../../../redux/actions/eventActions";
import { connect } from "react-redux";
import AddEventButton from './AddEventButton'

import './Events.css'

class Events extends Component {
  constructor(props){
    super(props) 
    this.state = { 
      showModal: false,
      content: null
    }
  }

  componentDidMount(){
    this.props.getEvents();
  }

  componentDidUpdate(){
      const { dataChanged } = this.props;
      if(dataChanged) {
        return this.props.setDataChange();
      }
  }
  
  updateState = (keys, values) => {
    keys.forEach((key, i)=>{
      this.setState({[key]: values[i]})
    })
  }

  render() {
    const { events, Navbar } = this.props;
    const { showModal, content } = this.state
    console.log(this.state)
    return (
      <>
        <Navbar/>
        <div className="sp-admin-events-container">
         <Modal 
            exitModal={()=>{this.updateState(["showModal", "content"],[false, null])}} 
            showModal={showModal}
          >
            {content}
          </Modal> 
          <AddEventButton updateState={this.updateState}/>
          {events.map((event)=> <EventCard updateState={this.updateState} event={event} /> )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
    dataChanged: state.app.dataChanged
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEvents: () => dispatch(getEvents()),
    setDataChange: () => dispatch(setDataChange()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
