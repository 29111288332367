import {
  FETCHING_TOKEN,
  DATA_FAILURE,
  GET_ACCESS_TOKEN,
  SAVE_SPOTIFY,
  SAVE_USER_RESULTS,
  ADD_SPOTIFY,
  UPDATE_SPOTIFY,
  SEARCH_VALUE,
} from "./types";
import * as spotifyApi from "../api/spotify.api"

// SPOTIFY QUESTIONNAIRE DATA
export const getAccessToken = (id) => async (dispatch) => {
  dispatch({ type: FETCHING_TOKEN });

  try {
    const data = await spotifyApi.fetchAccessToken()
    console.log("access token", data)
    dispatch({
      type: GET_ACCESS_TOKEN,
      accessToken: data.token,
      eventId: parseInt(id),
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: DATA_FAILURE });
  }
};

//GET SEARCH VALUE
export const getSearchValue = (query) => {
  return { type: SEARCH_VALUE, query };
};
// SAVE SPOTIFY SELECTION
export const saveSpotifyResults = (spotify) => {
  return { type: SAVE_SPOTIFY, spotify: true, song: spotify };
};
// SAVE SPOTIFY USER RESPONSES
export const saveUserSpotifyResults = (question) => {
  return { type: SAVE_USER_RESULTS, question };
};
// CREATE SPOTIFY INSTANCE
export const createSpotify = (spotify) => async (dispatch) => {
  try {
    const data = await spotifyApi.createSpotify(spotify)
    dispatch({
      type: ADD_SPOTIFY,
      song: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: DATA_FAILURE });
  }
};
// CREATE SPOTIFY INSTANCE
export const updateSpotify = (spotify) => async (dispatch) => {
  try {
    const data = await spotifyApi.updateSpotify(spotify)
    dispatch({
      type: UPDATE_SPOTIFY,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: DATA_FAILURE });
  }
};
