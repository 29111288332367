import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import QuestionnaireManager from "../questionnaire";
import { authenticateUser } from "../../../redux/actions/userActions";;

class Authenticate extends Component {
    async componentDidMount(){
        const { match } = this.props;
        const { id } = match.params
        //Broken
        const token = ""
        //Broken
        return await this.props.authenticateUser(token, id);
    }
    render() {
        return <QuestionnaireManager /> 
    }
}

const mapStateToProps = (state) =>{
    return {
        user: state.users.user,
        questionnaire: state.app.questionnaire,
        page: state.app.page,
        last: state.app.last
    }
}
const mapDispatchToProps = () => dispatch =>{ 
    return {
        authenticateUser: (token, id) => dispatch(authenticateUser(token,id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authenticate));
