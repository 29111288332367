import _ from 'lodash';


// COLOR PICKER FIELD DATA
export const colorPicker = [
  { label: "Select Background", name: "background" },
  { label: "Select Text Color", name: "textColor" },
];

// CREATES QUESTION OBJECT 
export const createQuestion = (values, id) => {
  // COMPONENT TYPES FOR FORM SCALING
  // ["text", "select", "email" , "multi-select", "number", "radio", "checkbox", "file"]

  let radio = {
    "Enter Text": "text",
    "Multiple Select": "select",
    "Spotify Search": "text",
    "Song Reason": "text",
  };

  let component = {
    "Enter Text": "text",
    "Multiple Select": "select",
    "Spotify Search": "search",
    "Song Reason": "reason", // NECESSARY TO IDENTIFY SPOTIFY FIELD TO PLACE ON PLAYLIST
  };

  let question = {
    id: 0,
    question: values.question,
    inputType: values.multiselect ? "multi-select" : radio[values.radio],
    inputName: values.multiselect ? "multi-select" : component[values.radio],
    spotify: false,
    choices: values.choices,
    order: 0,
    eventId: parseInt(id),
  };
  return question;
};

// QUESTION CREATED TO HOLD THE INITIAL VALUES OF THE FORM ON UPDATES
export const createUpdateQuestion = (question = {}, eventId) => {
  let radio = {
    text: "Enter Text",
    select: "Multiple Select",
    search: "Spotify Search",
    reason: "Song Reason",
  };

  const { inputType } = question;

  const choices = () => {
    if (question.choices) {
      return question.choices.map((question) => question.name);
    } else {
      return [];
    }
  };

  let values = {
    radio: radio[inputType],
    question: question["question"],
    multiselect: false,
    choices: choices(),
  };
  return values;
};

// PARSING CHOICE DATA TO BE UPDATED
export const parseUpdatedQuestion = (question, values) => {
  //REFACTOR THIS SECTION USING NORMALIZER TO UPDATE AND CREATE CHOICE EASIER OR MAKE A CREATE/UPDATE METHOD ON THE BACKEND

  const radioOptions = {
    "Enter Text": "text",
    "Multiple Select": "select",
    "Spotify Search": "search",
    "Song Reason": "reason",
  };

  // ERROR HANDLING
  if (!values) return values;

  const { choices, id, inputName, inputType, question: quest } = question;
  const { choices: userChoices, question: updateQuest, radio } = values;

  //CREATED OBJECTS FROM ORIGINAL QUESTION AND UPDATED QUESTION
  const newQuestion = { id, question: quest, inputType, inputName };
  const userQuestion = {
    id,
    question: updateQuest,
    inputType: values.multiselect ? "multi-select" : radio[values.radio],
    inputName: values.multiselect ? " multi-select" : radioOptions[radio],
  };

  //HANDLE UPDATE TO THE QUESTION
  let updatedQuestion = _.isEqual(newQuestion, userQuestion)
    ? false
    : Object.assign({}, question, userQuestion);

  // HANDLE CHOICE UPDATE
  let updatedChoices = userChoices
    .map((choice, idx) => {
      let newChoice = {};

      //CREATE UPDATED CHOICES OR NEWCHOICES
      if (choices[idx] === undefined) {
        return (newChoice = { name: userChoices[idx], questionId: id });
      } else if (choice !== choices[idx].name) {
        return (newChoice = {
          id: choices[idx].id,
          name: userChoices[idx],
          questionId: id,
        });
      }

      return newChoice;
    })
    .filter((choice) => !_.isEmpty(choice));

  // HANDLE EMPTY ARRAY
  updatedChoices = updatedChoices.length === 0 ? []: updatedChoices;

  return { updatedChoices, updatedQuestion };
};


// STATIC DATA FOR QUESTIONAIRE FORM
export const radioArray = [
  {
    name: "radio",
    component: "input",
    type: "radio",
    util: "text",
    value: "Enter Text",
    text: "Your respondents will enter text as their response",
  },
  {
    name: "radio",
    component: "input",
    type: "radio",
    value: "Multiple Select",
    text: "",
    util: "select",
  },
  {
    name: "radio",
    component: "input",
    type: "radio",
    value: "Spotify Search",
    text: "The users text entry will trigger a spotify search",
    util: "search",
  },
  {
    name: "radio",
    component: "input",
    type: "radio",
    value: "Song Reason",
    text:
      "User's text entry will be used to populate the cards on the front end",
    util: "reason",
  },
];
