import React, { Component } from 'react';
import Dashboard from "../Dashboard";
import Responses from './Responses';
import { connect } from "react-redux";
import { getResponses, getQuestionnaire } from "redux/actions/appActions";
import { getEvent } from "redux/actions/eventActions";

class ResponseContainer extends Component {
    async componentDidMount(){
        const { match, getEvent, getQuestionnaire, getResponses } = this.props;
        const { id } = match.params

        await getEvent(id);
        await getQuestionnaire(id);
        await getResponses(id);
    }


    render() {
      const { responses, questionnaire, events, Navbar } = this.props;
      const { event } = events;
      const { id, name } = event
        return (
          <React.Fragment>
            <Navbar event={event} showQuestions={true} />
            <Dashboard component={"Responses"} eventId={id} eventName={name} responses={responses} questionnaire={questionnaire} />
            <Responses tableData={responses} questionnaire={questionnaire} eventId={id} />
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    responses: state.app.responses,
    questionnaire: state.app.questionnaire
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getResponses: (id) => dispatch(getResponses(id)),
    getEvent: (id) => dispatch(getEvent(id)),
    getQuestionnaire: (id) => dispatch(getQuestionnaire(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponseContainer);
