import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk"; 
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";

const logger = createLogger({ collapsed: true });
const middleware = [thunk, logger];

const store = createStore(
  rootReducer,
  composeWithDevTools(
  applyMiddleware(...middleware))
);

export default store;
