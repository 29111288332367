import {
  GET_QUESTION,
  ADD_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  QUESTION_LOADING,
  QUESTION_FAILURE,
  ADD_CHOICES,
  UPDATE_CHOICE,
  DELETE_CHOICE,
  CHOICES_FAILURE,
  SET_DATA_CHANGED,
} from "./types";

import * as questionApi from "../api/question.api"

// FETCHED DATA
export const getQuestion = (id) => async (dispatch) => {
  try {
    const data = await questionApi.fetchQuestion(id)
    dispatch({
      type: GET_QUESTION,
      question: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: QUESTION_FAILURE });
  }
};

// QUESTIONS CRUD ACTIONS
export const addQuestion = (question) => async (dispatch, getState) => {
  //CHECK FOR CHOICES
  const choices = question.choices ? question.choices : [];

  //COPY OF QUESTIONLIST FROM CURRENT EVENT && ALSO UPDATES EVENT STATE WITH QUESTION
  const questions = [...getState().events.event.questions, question];

  // QUESTION LOADING
  dispatch({ type: QUESTION_LOADING });

  try {
    const data = await questionApi.createQuestion(question)
    return (
      dispatch({
        type: ADD_QUESTION,
        question: data.question,
        dataChanged: data.dataChanged,
        questions,
      }),
      choices.map((choice) => {
        if (choice) {
          return dispatch(
            addChoices(
              { name: choice, questionId: data.question.id },
              data.question.id,
              data
            )
          );
        }
      })
    );
  } catch (error) {
    console.log(error);
    dispatch({ type: QUESTION_FAILURE });
  }
};

export const updateQuestion = (id, question, choices) => async (dispatch, getState) => {
  //COPY OF QUESTIONLIST FROM CURRENT EVENT && ALSO UPDATES EVENT STATE WITH QUESTION
  const questions = [
    ...getState().events.event.questions.filter(
      (data) => data.id !== question.id
    ),
    question,
  ];

  // QUESTION LOADING
  dispatch({ type: QUESTION_LOADING });
  try {
    if (question) {
      const data = await questionApi.updateQuestion(id, question)
      return (
        dispatch({
          type: UPDATE_QUESTION,
          question: question,
          dataChanged: data.dataChanged,
          questions,
        }),
        choices.map((choice) => {
          if (choice && choice.id) {
            return dispatch(updateChoice(choice));
          } else if (choice && !choice.id) {
            return dispatch(addChoices(choice));
          } else {
            return null;
          }
        })
      );
    } else {
      const question = getState().events.event.questions.find(
        (data) => data.id === id
      );
      return choices.map((choice) => {
        if (choice && choice.id) {
          return dispatch(updateChoice(choice));
        } else if (choice && !choice.id) {
          return dispatch(addChoices(choice, id, question));
        } else {
          return;
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: QUESTION_FAILURE });
  }
};

export const deleteQuestion = (id) => async (dispatch, getState) => {
  //COPY OF QUESTIONLIST FROM CURRENT EVENT && ALSO UPDATES EVENT STATE WITH QUESTION
  const questions = [
    ...getState().events.event.questions.filter((data) => data.id !== id),
  ];

  try {
    const data = await questionApi.deleteQuestion(id)
    dispatch({
      type: DELETE_QUESTION,
      dataChanged: data.dataChanged,
      questions,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: QUESTION_FAILURE });
  }
};

// CHOICE RELATIONSHIP MANAGEMENT / CRUD ACTIONS
export const addChoices = (choice, questionId, question) => async (
  dispatch
) => {
  try {
    const data = await questionApi.createChoices(questionId, choice)
    
    dispatch({
      type: ADD_CHOICES,
      question: question.question,
      choice: data.choice,
      dataChanged: data.dataChanged,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: CHOICES_FAILURE });
  }
};

export const updateChoice = (choice) => async (dispatch) => {
  try {
    const data = await questionApi.updateChoices(choice)
    dispatch({
      type: UPDATE_CHOICE,
      dataChanged: data.dataChanged,
      choice: choice,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: CHOICES_FAILURE });
  }
};

export const deleteChoice = (id, questionId) => async (dispatch, getState) => {
  let question = getState().events.event.questions.find(
    (question) => question.id === questionId
  );

  try {
    const data = await questionApi.deleteQuestion(id)
    dispatch({
      type: DELETE_CHOICE,
      dataChanged: true,
      id: id,
      question: question,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: QUESTION_FAILURE });
  }
};

// APP STATE MANAGEMENT
export const setDataChange = () => (dispatch) => {
  dispatch({
    type: SET_DATA_CHANGED,
    dataChanged: false,
  });
};
