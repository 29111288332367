import React from "react";
import ReactDOM from "react-dom";
import Routes from "./components/Routes";
import store from "./redux/store";
import { Alert } from '@blueprintnyc/bpnyc-common';
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import {  BrowserRouter as Router } from "react-router-dom";
import 'index.css'

const { AlertBox } = Alert;


window.sp = {getState: store.getState}

ReactDOM.render(
  <Provider store={store}>
    <Router >
      <Routes />
    </Router>
    <AlertBox/>
  </Provider>,
  document.getElementById("root")
);
