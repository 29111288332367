class SpotifyApi {
  //GET ACCESS TOKEN
  static async getAccessToken() {
    try {
      const response = await fetch("/api/getTokens");
      const data = await response.json();
      console.log("access token", data);
      return data;
    } catch (e) {
      console.log(e);
      return { error: e };
    }
  }


    //SEARCH SPOTIFY
    static async getSpotifyResults(query, accessToken) {

        try {
            const response = await fetch(`/api/searchSpotify?accessToken=${accessToken}`,{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(query)
                })
                const result = await response.json()
   
                let sortMapped = []
                
                sortMapped = result.data.map(song => {
                    console.log('song', song)
                   return sortMapped = {
                        id: song.id,
                        uri: song.uri,
                        song: song.name,
                        artist: song.artists[0].name || "",
                        album: song.album.id,
                        albumUrl: song.album.images[0].url,
                        url: song.external_urls.spotify
                    }
                })
                return sortMapped;
        } catch (err) {
            console.log("error", err)
            return { error: err }
        }
    }

  //CREATE SPOTIFY INSTANCE
  static async createSpotify(spotify) {
    const response = await fetch("/admin/spotifies", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(spotify)
    });
    const data = await response.json();
    return data;
  }
}

export default SpotifyApi;
