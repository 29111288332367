import React from 'react';
import { Table, Card } from 'reactstrap';
import Checkboxes from "./CheckBoxes";
import { structureTableData, capitalize, createSpotifyLink } from "../../../helpers/response";
import { Link } from '@material-ui/core';

const ResponseTable = (props) =>  {
 const { tableData, questionnaire } = props;
 const { showTable, tableRows, questionnaireHeaders, spotifyData } = structureTableData(tableData, questionnaire);

  if (!showTable) {
    return (
      <Card style={styles.card}>"No Responses Available Yet"</Card>
    );
  } else {
    return (
        <div style={styles.container}>
        <Table
            size="sm"
            style={styles.table}
        >
            <thead>
            <tr>
                {questionnaireHeaders.map((title) => (
                  <th key={title}>{capitalize(title)}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {tableRows.map((response, index) => {
              return (
                <tr key={index}>
                  {questionnaireHeaders.map((item, idx) => {
                    if (idx === 0) {
                      return (
                        <td key={idx} style={{ textAlign: "center" }}>
                          <Checkboxes
                            response={response}
                            responses={tableData}
                            reviewed={response[item]}
                          />
                        </td>
                      );
                    } else {
                      return (
                        <td key={idx}>
                        {createSpotifyLink(response[item], spotifyData[index]) ? 
                          <Link href={createSpotifyLink(response[item], spotifyData[index])}>{response[item]} </Link>
                          : response[item] }
                        </td>
                      )
                    }
                  })}
                </tr>
              ); 
            })}
            </tbody>
        </Table>
        </div>
    );
  }
};


const styles = {
  card: {
    top: "4em",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    textAlign: "center",
    width: "50vw",
    height: "30vh",
    margin: "auto",
  },
  table: {
    width: "80em",
    margin: "0 auto",
    padding: "1em",
    marginTop: "1.5em",
  },
  container: {
    overflow: "scroll",
    height: "95vh",
  },
};

export default ResponseTable;

